<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>DOKUMEN PENDUKUNG TERKAIT LAPORAN KEPENGURUSAN, PEJABAT EKSEKUTIF, DAN JARINGAN KANTOR BPR</strong>
          <br>
          <a-button class="btn btn-outline-success" @click="txtExport('rutin')">Rutin</a-button>
          <a-button class="btn btn-outline-warning ml-2" @click="txtExport('koreksi')">Koreksi</a-button>
          <a-button class="btn btn-outline-primary ml-2" @click="txtExport('kap')">Koreksi KAP</a-button>
          <a-button class="btn btn-outline-dark ml-2" @click="txtExport('pengawas')">Koreksi Pengawas</a-button>
        </template>
      </a-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { jsPDF } from 'jspdf'

export default {
  methods: {
    txtExport(e) {
      var user = this.$store.state.user
      const JSPDF = jsPDF
      const doc = new JSPDF()
      var RorK = e === 'rutin' ? 'R' : 'K'
      var posisiperiode = moment(user.periode, 'YYYY-MM-DD').endOf('months').format('YYYYMMDD')
      var filename = 'LBBPRK-' + '0013' + '-' + RorK + '-M-' + posisiperiode + '-' + user.sandibpr + '-01'
      doc.save(filename + '.pdf')
    },
  },
}
</script>

<style>

</style>
